<template>
  <div class="serveBox">
    <div
      class="trainingCourse"
      v-for="(item, i) in data"
      :key="i"
      @click="
        $router.push(
          'itemListInfo' + '?id=' + item.Id + '&apiPath=' + 'productionService'
        )
      "
    >
      <div class="CourseImgBox">
        <img
          class="CourseImg"
          v-real-img="item.CoverImage"
          src="@/assets/images/nodata.png"
          alt=""
        />
      </div>
      <div class="CourseTitle">{{ item.Name }}</div>
      <div class="CourseText" v-html="item.Description">
        <!-- {{ item.Description }} -->
      </div>
    </div>
    <div class="trainingCourseNoData" v-if="!data || data.length <= 0">
      <span class="img">暂无数据~</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    data: {
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped lang="scss">
.serveBox {
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}
.trainingCourse {
  margin-top: 16px;
  width: 355px;
  height: 319px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
  border-radius: 8px;
  margin-right: 17px;

  &:nth-child(3n) {
    margin-right: 0px;
  }
  .CourseImgBox {
    width: 355px;
    height: 198px;
    .CourseImg {
      width: 100%;
      height: 100%;
    }
  }
  .CourseTitle {
    box-sizing: border-box;
    width: 100%;
    height: 28px;
    padding-left: 16px;
    margin: 16px 0 0 0;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }
  .CourseText {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 0px 16px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
}
.trainingCourseNoData {
  margin-top: 16px;
  width: 1100px;
  height: 319px;
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    color: #3d5afe;
    // width: 426px;
    // height: auto;
    max-height: 319px;
  }
}
</style>
