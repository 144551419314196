<template>
  <div class="servsBox">
    <div class="serve-center">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: 'firmInfo' }"
            >合作企业</el-breadcrumb-item
          >
          <el-breadcrumb-item>产品服务</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="Details" v-if="ServePageList.length > 0">
        <ServeListCard :data="ServePageList" />
      </div>
      <div class="Details" v-else>
        <div class="NoData">当前无数据~</div>
      </div>
      <div class="loading" v-if="totalCount > 10">
        <Pagination
          :total="totalCount"
          :limit="10"
          :layout="'prev, pager, next'"
          @pagination="areaPagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ServeListCard from "./ServeListCard.vue";
import { getServePageList } from "@/api/enterprise/enterprise";

export default {
  name: "servsBox",
  props: {},
  components: {
    ServeListCard,
    Pagination
  },
  data() {
    return {
      // 服务
      ServePageList: [],
      totalCount: 0,
      queryServeData: {
        EnterpriseId: this.$route.query.id,
        PageSize: 10,
        PageIndex: 1
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 服务
    async getServeList() {
      this.loading = true;
      try {
        const { Result } = await getServePageList(this.queryServeData);
        let res = { ...Result, Type: "Serve" };
        this.ServePageList = res.Data;
        this.totalCount = res.TotalCount;
        // console.log("服务", res);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    areaPagination(val) {
      this.queryServeData.PageIndex = val.page;
      this.getServeList();
    }
  },
  created() {
    this.getServeList();
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.servsBox {
  width: 100%;
  min-height: calc(100vh - 202px);
  background: #f5f5f5;
  .serve-center {
    box-sizing: border-box;
    width: 1100px;
    margin: 0 auto;
    padding-bottom: 25px;
    .breadcrumb {
      box-sizing: border-box;
      width: 100%;
      height: 46px;
      padding: 16px 0 8px;
    }
    .Details {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;
      min-height: 500px;
      overflow: hidden;
      .NoData {
        width: 100%;
        height: 300px;
        line-height: 300px;
        text-align: center;
        color: #557df7;
      }
      .itemLists {
        min-height: 70px;
      }
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  text-align: center;
  /deep/ .pagination-container {
    background: #f5f5f5;
  }
}
</style>
